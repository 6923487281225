import React, { useState, useEffect } from "react";
import styled from "styled-components";

const AlertContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 5;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s, transform 0.3s;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateX(-50%)
    translateY(${(props) => (props.visible ? 0 : "20px")});
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};
  background-color: ${(props) => {
    switch (props.type) {
      case "success":
        return "#3CCC38";
      case "error":
        return "#EA475D";
      case "warning":
        return "#F7CE53";
      case "info":
        return "#6569DF";
      default:
        return "#f0f0f0";
    }
  }};
  color: #fff;
`;

const Alert = ({ type = "default", message }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <AlertContainer type={type} visible={visible}>
      {message}
    </AlertContainer>
  );
};

export default Alert;
