import React, { useState } from "react";
import styled from 'styled-components';
import theme from "../../assets/styles/theme/theme";

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 5px;
  @media (max-width: 797px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const Label = styled.label`
  margin-right: 10px;
`;

const InputBox = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 260px;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: ${theme.colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

function Newsletter() {
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleButtonClick = () => {
        // Handle the button click action here
        console.log("Button clicked!");
    };

    return (
        <div>


            <Label>Subscribe to our newsletter</Label>

            <FormContainer>
                <InputBox type="text" value={inputValue} onChange={handleInputChange} placeholder="Your Email Address" />
                <Button onClick={handleButtonClick}>Submit</Button>
            </FormContainer>
        </div>
    );
}

export default Newsletter;
