export const MenuItems = [
  {
    title: "QR Based Payment",
    path: "/QR-based-payment",
    cName: "dropdown-link",
  },
  // {
  //   title: "Consulting",
  //   path: "/consulting",
  //   cName: "dropdown-link"
  // },
  // {
  //   title: "Design",
  //   path: "/design",
  //   cName: "dropdown-link"
  // },
  // {
  //   title: "Development",
  //   path: "/development",
  //   cName: "dropdown-link"
  // }
];
