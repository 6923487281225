import React from "react";
import styled from "styled-components";
import theme from "../../assets/styles/theme/theme";

const Button = ({ onClick, text, bgColor }) => {
  return <ButtonStyled onClick={onClick} bg={bgColor}>{text}</ButtonStyled>;
};

export default Button;

const ButtonStyled = styled.button`
  /* basic button layout */
  background-color: ${props => props.bg  || theme.colors.secondary};
  padding: 5px 10px;
  font-size: 16px;
  width: 160px;
  cursor: pointer;
  border-color: ${props => props.bg  || theme.colors.secondary};
  color: ${theme.colors.white};
  border-radius: 20px;
  border: none;

  &:hover {
    // background-color: ${theme.colors.white};
    // color: ${theme.colors.secondary};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;
