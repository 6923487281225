import React from 'react'
import { styled } from 'styled-components';
import Heading from './Heading';
import Paragraph from './Paragraph';
import { MainContainer } from '../../assets/styles/theme/styles';
import theme from '../../assets/styles/theme/theme';

export default function FlexCardIcon({heading,para,cardData}) {

  return (
    <MainContainer>
    <HeroItem>
          <Heading level={1} style={{textAlign:"center"}}>{heading}</Heading>
          {para && <Paragraph level={1}>{para}</Paragraph>}
        </HeroItem>
    <WeHelpContainer>{cardData.map((curEle, ind)=>{
        return(
            <WeHelpItem key={ind}>
                <CardFlex>
                    <img src={curEle.cardIcon} alt={curEle.cardIcon} />
                    <Heading level={3}>{curEle.cardTitle}</Heading>
                    <Paragraph level={4}>{curEle.cardDescription}</Paragraph>
                </CardFlex>
            </WeHelpItem>
        )
      
    })
        
        }
     </WeHelpContainer>
     </MainContainer>
  )
}


const WeHelpContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap:2rem;
`;

const WeHelpItem = styled.div`
  color: ${theme.colors.secondary};
  text-align: center;
  width: 350px;
  margin: 10px;
  position: relative;
  border-radius: 20px;
  background-color: ${theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  @media (min-width: 1020px) {
  }
`;

const HeroItem = styled.div`
  /* add styles for hero item */
  margin-bottom: 3rem;
  width: 56%;
  margin: 0px auto;
`;

const CardFlex = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 20px 3rem;
img {
    width: 100px;
}

`;