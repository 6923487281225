import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MenuItems } from "./MenuItems";
import styled from "styled-components";

export default function Dropdown() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <>
      <StyledUl onClick={handleClick} clicked={click}>
        {MenuItems.map((item, index) => {
          return (
            <li key={index}>
              <StyledLink to={item.path} onClick={() => setClick(false)}>
                {item.title}
              </StyledLink>
            </li>
          );
        })}
      </StyledUl>
    </>
  );
}


const StyledUl = styled.ul`
  width: 200px;
  position: absolute;
  top: 75px;
  list-style: none;
  text-align: start;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: 90px;
  border-radius: 10px;
  background: white;
  display: ${({ clicked }) => (clicked ? "none" : "block")};
`;

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: black;
  padding: 16px;
  cursor: pointer;

  &:hover {
    color: #5cabff;
  }
`;


