import React from 'react'
import CommonDiv1 from '../pricing/components/CommonDiv1'
import Images from '../../utils/Images'
import GridTextContainer from '../../utils/GridTextContainer'

export default function About() {
  const helpContent = [
    {
      title: "Secure Payment Processing",
      description:
        `Security is at the core of everything we do. Our
            advanced encryption and fraud prevention
            measures ensure that every transaction is
            safeguarded, providing peace of mind to both
            businesses and customers.`,
    },
    {
      title: "Seamless Integration",
      description:
        `Our payment gateway seamlessly integrates with
            various platforms and e-commerce systems,
            making it easy for businesses to start accepting
            payments swiftly.
            `,
    },
    {
      title: "Wide Range of Payment Options",
      description:
        `We support a diverse array of payment methods,
            including credit/debit cards, mobile wallets, online
            banking, and more, allowing businesses to cater to
            a global customer base.`,
    },
    {
      title: "Reporting & Analytics",
      description:
        `Access detailed insights into your transaction
            data with our robust reporting and analytics
            tools, enabling you to make informed business
            decisions.`,
    },
    {
      title: "24/7 Customer Support",
      description:
        `We take pride in offering excellent customer
            support around the clock. Our dedicated
            support team is always ready to assist you
            with any inquiries or technical issues.
            `,
    },
  ];
  return (
    <>
      {/* <HeroContainer imageUrl={IMAGES.smsbgdiv1} ht="1200px" bgSize="cover"> */}
      <CommonDiv1
        title={`About Us`}
        description={`Kahipay is your reliable and innovative payment gateway solution provider. We are committed to empowering businesses of all sizes with secure, seamless, and efficient payment processing services. <br/><br/> 
                Our mission is to simplify online transactions, enabling businesses to focus on growth while offering their customers a smooth and secure payment experience.
          `}
        fgimage={Images.About_us}
        btns={["Contact Us"]}
      />
      {/* </HeroContainer> */}

      <GridTextContainer
        heading="What We Offer"
        helpContent={helpContent}
      />
    </>
  )
}
