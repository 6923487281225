import React from 'react'
import CommonDiv1 from '../pricing/components/CommonDiv1'
import Images from '../../utils/Images'
import GridTextContainer from '../../utils/GridTextContainer'
import ImageTextSection from '../../utils/ImageTextSection';

export default function QRpayment() {

    const helpContent = [
        {
          title: "Save Time",
          description:
            `No more waiting in checkout lines. Shop
            efficiently and get on with your day
            in record time.`,
        },
        {
          title: "Convenient and Contactless",
          description:
            `Minimize physical contact by scanning
            and paying for items directly
            on your device.
            `,
        },
        {
          title: "Stay Organized",
          description:
            `Keep track of your purchases with
            digital receipts, helping you manage your
            expenses effortlessly. `,
        },
        {
          title: "Tech-Savvy Shopping",
          description:
            `Embrace the latest in retail
            technology and enjoy a modern
            shopping experience.
            `,
        },
        {
          title: "Exclusive Offers",
          description:
            `Unlock special offers and
            discounts available exclusively to
            Scan & Pay users.`
        },

      ];

      const text2 = [
        `Introducing our convenient and time-saving Scan & Pay feature, designed to revolutionize your shopping experience. Say goodbye to long checkout lines and hello to effortless shopping with just a few taps on your mobile device. With Scan & Pay, you can breeze through the store, scan your items, and pay securely in no time!`,
      ];

  return (
    <>
      {/* <HeroContainer imageUrl={IMAGES.smsbgdiv1} ht="1200px" bgSize="cover"> */}
      <CommonDiv1
        title={`Digital Payments
        with Scan & Pay`}
        description={`QR Code-based solution enables our clients to
        accept payments using mobile phones Its easy,
        quick, and secure option to pay, along with
        sound box with multiple language.
        `}
        fgimage={Images.qrpay}
        btns={["Contact Us"]}
      />
      {/* </HeroContainer> */}

      <ImageTextSection
        heading="Scan & Pay with Kahipay"
        text={text2}
        imageSrc={Images.paySpeaker}
        drn={"row-reverse"}
      />

      <GridTextContainer
          helpContent={helpContent}
        />
    </>
  )
}
