import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../assets/styles/theme/theme';

const InputField = ({ label, placeholder, type, required, onBlur, value, onChange, error, minLength, maxLength }) => {


  return (
    <InputContainer>
      <InputLabel>
        {label} {required && <Asterisk>*</Asterisk>}
      </InputLabel>
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        minLength={minLength}
        maxLength={maxLength}
        required={required}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </InputContainer>
  );
};

export default InputField;

// Styled components
const InputContainer = styled.div`
  margin: 10px 0;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-size: 15px;
`;

const Asterisk = styled.span`
  color: red;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 8px;
  background-color: inherit;
  border: 2px solid ${theme.colors.skyBluedark};
  border-radius: 5px;
  &::placeholder {
    font-size: 15px;
  }
`;

const ErrorText = styled.p`
color: red;`;

export {
  InputLabel,
  Asterisk,
  ErrorText
};
