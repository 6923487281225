import CryptoJS from "crypto-js";

const AESCipher = ({ encryptionKey }) => {
  const bs = 16; // AES block size
  const key = CryptoJS.SHA256(encryptionKey);

  const encrypt = (raw) => {
    const iv = CryptoJS.lib.WordArray.random(bs); // Generate a random IV
    const encrypted = CryptoJS.AES.encrypt(raw, key, {
      iv: iv,
      mode: CryptoJS.mode.CFB, // You can choose the mode you prefer
    });
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  };

  const decrypt = (enc) => {
    const ciphertext = CryptoJS.enc.Base64.parse(enc);
    const iv = ciphertext.clone();
    iv.sigBytes = bs; // Set the IV length
    iv.clamp(); // Fix IV
    ciphertext.words.splice(0, bs / 4); // Remove IV from ciphertext
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: ciphertext },
      key,
      { iv: iv, mode: CryptoJS.mode.CFB } // Use the same mode you used for encryption
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  };

  return { encrypt, decrypt };
};

export default AESCipher;
