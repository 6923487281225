import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import BrandLogo from "../utils/BrandLogo";
import Newsletter from "../utils/Newsletter";
import theme from "../../assets/styles/theme/theme";

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 30px;
  padding-top: 8rem;
  color: #687992;
  background-color: ${theme.colors.white};
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  max-width: 1240px;
  width: 100%;
  margin: 2px auto;
  & > *:first-child {
    flex-grow: 2;
  }

  & > *:not(:first-child) {
    flex-grow: 1;
  }

  @media screen and (max-width: 800px) {
    display: block;

    & > *:first-child {
      margin-bottom: 1rem;
    }
  }
`;

const LinkList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const LinkListItem = styled.li`
  margin-bottom: 10px;
`;

const StyledLink = styled(Link)`
  position: relative;
  overflow: hidden;
  text-decoration: none;
  display: block;
  width: fit-content;
  cursor: pointer;
  font-size: 14px;
  color: black;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background-color: ${theme.colors.primary};
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  &:hover:after {
    transform: scaleX(1);
  }
`;

const SmallCard = styled.span`
  text-align: center;
  margin-top: 50px;
  font-size: 14px;
  a {
    color: black;
    text-decoration: none;
  }
`;

const CopyrightText = styled.p`
  text-align: center;
  font-size: 12px;
  margin: 1rem 0;
`;

function Footer() {
  return (
    <FooterContainer>
      <CardWrapper>
        <section>
          <BrandLogo />
          <Newsletter />
        </section>

        <section>
          <LinkList className="fnt-size link">
            <LinkListItem>
              <StyledLink to="/">Home</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/pricing">Pricing</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/about-us">About us</StyledLink>
            </LinkListItem>
          </LinkList>
        </section>

        <section>
          <LinkList className="fnt-size link">
            <LinkListItem>
              <StyledLink to="">Grievance Redressal</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to={`privacy-policy`}>Privacy</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to={`terms-of-use`}>Terms & Conditions</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/sms/#smsHome">Disclaimer</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/cpaas/#cpaasHome">Disclosures</StyledLink>
            </LinkListItem>
          </LinkList>
        </section>

        <section>
          <LinkList className="fnt-size link">
            <LinkListItem>
              <StyledLink to="/contact-us">Contact Us</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/careers">Career</StyledLink>
            </LinkListItem>
          </LinkList>
        </section>
      </CardWrapper>

      <SmallCard>
        {/* <a href="tel:+910000000000">+91 00000 00000</a> */}
        {/* {" | "} */}
        <a href="mailto:hello@kahipay.com">hello@kahipay.com</a>
      </SmallCard>

      <CopyrightText>
        © 2023 Copyright Kahipay Technologies Pvt. Ltd. All rights reserved.
      </CopyrightText>
    </FooterContainer>
  );
}

export default Footer;
