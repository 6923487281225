import axios from "axios";
import { signupURL } from "./dotenv";

// Set the base URL for your API
const api = axios.create({
  baseURL: signupURL,
});

// Define a function for making GET requests
export const get = async (url) => {
  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Define a function for making POST requests
export const post = async (url, data) => {
  try {
    const response = await api.post(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Define a function for making PUT requests
export const put = async (url, data) => {
  try {
    const response = await api.put(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
