import React, { Fragment, useState } from "react";
import { BsFillCaretDownFill } from "react-icons/bs";
import styled from "styled-components";
import theme from "../../assets/styles/theme/theme";
import { MainContainer } from "../../assets/styles/theme/styles";
import InputField from "../utils/InputField";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Heading = styled.h2`
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-bottom: 4rem;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-size: 15px;
`;

const Asterisk = styled.span`
  color: red;
`;

const CustomForm = styled.div`
// width: 300px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
 
 > div {
    max-width: 400px;
    width: 100%;
    margin: 10px 0;
 }
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid ${theme.colors.skyBluedark};

  padding: 7px;
  border-radius: 5px;
`;

const Dropdown = styled.select`
  flex: 1;
  border: none;
  outline: none;
  padding: 5px;
  background-color: transparent;
  cursor: pointer;
  appearance: none;
`;

const DropdownIcon = styled(BsFillCaretDownFill)`
  color: ${theme.colors.skyBluedark};;
  margin-left: 5px;
`;

const SubmitButton = styled.button`
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 10px 70px;
  background-color: ${theme.colors.skyBluedark};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
`;

const KYCForm = () => {
  const [isHaveGST, setIsHaveGST] = useState("Yes");
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedID, setSelectedID] = useState("");
  const [selectedIdDisplay, setSelectedIdDisplay] = useState("");

  const [formData, setFormData] = useState({
    businessType: "",
    panCardNumber: "",
    gstNumber: "",
    bankName: "",
    ifcCode: "",
    accountNumber: "",
    selectedIdNumber: ""
  });

  const [errors, setErrors] = useState({
    panNumber: '',
    GST: '',
    bankName: '',
    bankIFSC: '',
    accountNumber: '',
    identityDetail: '',
  });

  // Regex validation for feidls
  const validatePan = (pan) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/gm;
    return panRegex.test(pan);
  }

  const validateGST = (gst) => {
    const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/;
    return gstRegex.test(gst);
  }
  const validateBankName = (bankname) => {
    const bankNameRegex = /^[A-Za-z\s&.()/-]+$/;
    return bankNameRegex.test(bankname);
  }

  const validateBankIFSC = (ifsc) => {
    const ifscRegex = /^[A-Z]{4}[0-9]{7}$/;
    return ifscRegex.test(ifsc);
  }

  const validateBankAccountNumber = (number) => {
    const acc_Regex = /^\d{9,18}$/;
    return acc_Regex.test(number);
  }

  const validateIdentityDetail = (idRegex, number) => {
    const identityRegex = idRegex;
    return identityRegex.test(number);
  }




  const handleFieldChange = (fieldName, value) => {
    // console.log("clicked me", fieldName, value);
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value
    }));


    // Validate email and password as they are type
    switch (fieldName) {
      case 'panCardNumber':
        if (!validatePan(value)) {
          setErrors({ ...errors, panNumber: 'Invalid PAN number' });
        } else {
          setErrors({ ...errors, panNumber: '' });
        }
        break;

      case 'gstNumber':
        if (!validateGST(value)) {
          setErrors({ ...errors, GST: 'Invalid GST number' });
        } else {
          setErrors({ ...errors, GST: '' });
        }
        break;

      case 'bankName':
        if (!validateBankName(value)) {
          setErrors({ ...errors, bankName: 'Numbers are not allowed' });
        } else {
          setErrors({ ...errors, bankName: '' });
        }
        break;

      case 'ifcCode':
        if (!validateBankIFSC(value)) {
          setErrors({ ...errors, bankIFSC: 'Invalid IFSC code' });
        } else {
          setErrors({ ...errors, bankIFSC: '' });

        }
        break;

      case 'accountNumber':
        if (!validateBankAccountNumber(value)) {
          setErrors({ ...errors, accountNumber: 'Invalid account number' });
        } else {
          setErrors({ ...errors, accountNumber: '' });
        }
        break;

      case 'accountNumber':
        let identityDetailRegex;
        if (selectedID === "Aaddhar Card") {
          identityDetailRegex = /^\d{4}\s\d{4}\s\d{4}$/g;
        } else if (selectedID === "Driving Licence") {
          identityDetailRegex = /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/gm;
        } else if (selectedID === "Udhyog Adhar") {
          identityDetailRegex = /\b[A-Z]{2}\d{2}[A-Z]\d{7}\.\d{2}\b/;
        } else if (selectedID === "Passport Number") {
          identityDetailRegex = /^[A-Z]{1}[0-9]{7}$/;
        } else if (selectedID === "Voter ID") {
          identityDetailRegex = /^([a-zA-Z]){3}([0-9]){7}?$/g;
        }


        if (!validateIdentityDetail(value)) {
          setErrors({ ...errors, identityDetail: `Invalid ${selectedID} format` });
        } else {
          setErrors({ ...errors, identityDetail: '' });
        }
        break;


    }
  };

  const handleIdentityChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedID(selectedValue);

    // setting dynamic selected Identity Detail on UI
    switch (selectedValue) {
      case "aadhar_card":
        setSelectedIdDisplay("Aaddhar Card");
        break;

      case "driving_licence":
        setSelectedIdDisplay("Driving Licence");
        break;

      case "udhyog_aadhar":
        setSelectedIdDisplay("Udhyog Adhar");
        break;

      case "passport":
        setSelectedIdDisplay("Passport Number");
        break;

      case "voter_id":
        setSelectedIdDisplay("Voter ID");
        break;

      default:
        setSelectedIdDisplay("Select an option");
    }

    //validate on more time before hitting post API




    //conditionally sending payload based on isHaveGST have or not.



    // after taking all things hit post api.
  }




  const handleSubmit = () => {
    console.log(`x  ${formData.businessType}, 
    2)pan: ${formData.panCardNumber} 3) isHaveGST: ${isHaveGST} 4) gst number:${formData.gstNumber} all data: ${JSON.stringify(formData)}`);



  };



  return (
    <MainContainer>
      <FormContainer>
        <Heading>KYC Details</Heading>
        <CustomForm >
          <div>
            <InputLabel>
              Select Business Type <Asterisk>*</Asterisk>
            </InputLabel>
            <DropdownContainer>
              <Dropdown value={formData.businessType} onChange={(e) => handleFieldChange("businessType", e.target.value)}
              >
                <option value="">Select an option</option>
                <option value="Proprietor / Individual">
                  Proprietor / Individual
                </option>
                <option value="Private Ltd">Private Ltd</option>
                <option value="Partnership">Partnership</option>
              </Dropdown>
              <DropdownIcon />
            </DropdownContainer>
          </div>
          <div>
            <InputField
              label="PAN Card Detail"
              placeholder="Enter Pan Card Number"
              type={"text"}
              name="pan"
              value={formData.panCardNumber}
              // onBlur={()=> handleBlur("businessName")}
              onChange={(e) => handleFieldChange("panCardNumber", e.target.value)}
              error={errors.panNumber}
              required
            />
          </div>
          <div>
            <InputLabel>
              GST Detail <Asterisk>*</Asterisk>
            </InputLabel>
            <DropdownContainer>
              <Dropdown value={isHaveGST} onChange={(e) => {
                setIsHaveGST(e.target.value)
              }}>
                <option value="Yes">
                  GST Number
                </option>
                <option value="No">Bank & ID Details</option>
              </Dropdown>
              <DropdownIcon />
            </DropdownContainer>
          </div>
          {
            isHaveGST === "Yes" ? (<div>
              <InputField
                label="GST Number"
                placeholder="Enter GST Number"
                type={"text"}
                value={formData.gstNumber}
                // onBlur={()=> handleBlur("businessName")}
                onChange={(e) => handleFieldChange("gstNumber", e.target.value)}
                error={errors.GST}
                required
              />
            </div>) : (
              <>
                <div>
                  <InputField
                    label="Bank Name"
                    placeholder="Enter Bank Name"
                    type={"text"}
                    maxLength={50}
                    value={formData.bankName}
                    // onBlur={()=> handleBlur("businessName")}
                    onChange={(e) => handleFieldChange("bankName", e.target.value)}
                    error={errors.bankName}
                    required
                  />
                </div>

                <div>
                  <InputField
                    label="IFSC Code"
                    placeholder="Enter IFSC Code"
                    type={"text"}
                    maxLength={20}
                    value={formData.ifcCode}
                    // onBlur={()=> handleBlur("businessName")}
                    onChange={(e) => handleFieldChange("ifcCode", e.target.value)}
                    error={errors.bankIFSC}
                    required
                  />
                </div>

                <div>
                  <InputField
                    label="Account Number"
                    placeholder="Enter Account Number"
                    type={"text"}
                    value={formData.accountNumber}
                    // onBlur={()=> handleBlur("businessName")}
                    onChange={(e) => handleFieldChange("accountNumber", e.target.value)}
                    error={errors.accountNumber}
                    required
                  />
                </div>


                <div>
                  <InputLabel>
                    Identity Detail <Asterisk>*</Asterisk>
                  </InputLabel>
                  <DropdownContainer>
                    <Dropdown value={selectedID} onChange={handleIdentityChange}>
                      <option value="">Select an option</option>
                      <option value="aadhar_card">Aadhar Card</option>
                      <option value="driving_licence">Driving Licence</option>
                      <option value="udhyog_aadhar">Udhyog Aadhar</option>
                      <option value="passport">Passport</option>
                      <option value="voter_id">Voter ID</option>
                    </Dropdown>
                    <DropdownIcon />
                  </DropdownContainer>
                </div>
                {
                  selectedID &&
                  <div>
                    <InputField
                      label={selectedIdDisplay}
                      placeholder={`Enter ${selectedIdDisplay}`}
                      type={"text"}
                      value={formData.selectedIdNumber}
                      // onBlur={()=> handleBlur("businessName")}
                      onChange={(e) => handleFieldChange("selectedIdNumber", e.target.value)}
                      // error={
                      //   touchedFields.businessName &&
                      //   businessName.length === 0
                      // }
                      required
                    />
                  </div>
                }

              </>
            )
          }
        </CustomForm>
        <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
      </FormContainer>
    </MainContainer>

  );
};

export default KYCForm;
