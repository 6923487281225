import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/footer/Footer";
import ContactUS from "./components/Pages/contact/ContactUS";
import Pricing from "./components/Pages/pricing/Pricing";
import About from "./components/Pages/about/About";
import Home from "./components/Pages/Home";
import QRpayment from "./components/Pages/products/QRpayment";
import { GlobalStyle } from "./assets/styles/globalStyles";
import Login from "./components/Pages/Login";
import Signup from "./components/Pages/Signup";
import ScrollToTop from "./components/utils/ScrollToTop";
import KYCForm from "./components/Pages/KYCForm";

export default function App() {
  return (
    <div>
      <GlobalStyle />
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUS />} />
        <Route path="/QR-based-payment" element={<QRpayment />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/kyc-detail" element={<KYCForm />} />
      </Routes>
      <Footer />
    </div>
  );
}
