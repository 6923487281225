import React, { useState } from 'react'
import styled from "styled-components";
import theme from "../../assets/styles/theme/theme";
import { MainContainer } from "../../assets/styles/theme/styles";
import { Heading, Container, FormButton, SignupText, SignupLink, PasswordIcon, InputIcon, RememberForgotWrapper, RememberCheckbox, ForgotLink, LinkBase } from './Login';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import usePasswordToggle from '../utils/usePasswordToggle';
import InputField, { Asterisk, ErrorText, InputLabel } from '../utils/InputField';
import { post } from '../utils/apiManager';
import Alert from '../utils/Alert';


export default function Signup() {
  const [passwordVisible, togglePasswordVisibility] = usePasswordToggle();

  const [formData, setFormData] = useState({
    businessName: "",
    emailId: "",
    mobileNumber: "",
    password: ""
  });

  const [errorFields, setErrorFields] = useState({
    businessName: false,
    emailId: false,
    mobileNumber: false,
    password: false
  });

  const [apiResponse, setApiResponse] = useState({
    success: false,
    message: "",
  });

  const validationPatterns = {
    businessName: {
      regex: /^(?=.*[A-Za-z])[A-Za-z0-9_,./()\s'&#+*-]+$/,
      message: "Allowed alphanumeric characters & these special characters: _,./()'&#+*-",
    },
    emailId: {
      regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: "Please enter a valid email Id",
    },
    mobileNumber: {
      regex: /^(0|91)?[6-9][0-9]{9}$/,
      message: "Please enter 10 digit mobile number without country code",
    },
    password: {
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.*()_&])[A-Za-z\d@.*()_&]{8,15}$/,
      message: "Password must contain 8-15 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character from the set @ . _ ( * ) &",
    },
  };

  const handleBlur = (fieldName, value,regexPattern) => {
    // console.log(`handle blur data fieldName:${fieldName}, value:${value}, regexPattern: ${regexPattern} `);
    const regex = regexPattern; 
    // Test the input value against the regex pattern
    const isValid = regex.test(value);  // valid - true,  not valid - false.

        setErrorFields((prevErrorFields) => ({
          ...prevErrorFields,
          [fieldName]: !isValid 
        }));
  };    


  const handleFieldChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Reset the apiResponse state to initial values
    setApiResponse({
      success: false,
      message: "",
    });

    let hasError = false;
    //Loop through formData keys and check validation
    for (const field in formData) {
      if (formData.hasOwnProperty(field)) {
        const value = formData[field];
        const regexPattern = validationPatterns[field].regex;
        const isValid = regexPattern.test(value);
  
        if (!isValid) {
          setErrorFields((prevErrorFields) => ({
            ...prevErrorFields,
            [field]: true,
          }));
          hasError = true; //Set the error flag to true if any field is invalid
        }
      }
    }
    // console.log(`proceed to hit API before ${hasError}`);

  if(!hasError){
        // console.log("Form submitted:", formData);
        // console.log(`proceed to hit API after ${hasError}`);
    post('/signup', formData)
      .then(data => {
        console.log('POST response:', data);
      // Clear the formData after a successful API hit
      setFormData({
        businessName: "",
        emailId: "",
        mobileNumber: "",
        password: ""
      });
        // Update the state with the API response
        setApiResponse({
          success: true, //Set to true for success, false for error
          message: data.message, // set static or dynamic response message
        });

      })
      .catch(error => {
        console.error('POST error:', error);
        // console.error('POST error detailed:', error.response.data.error);

          // Update the state with the API response
          setApiResponse({
            success: false,
            message: error.response.data.error,
          });

      });
  }
    
  };


  const { businessName, emailId, mobileNumber, password } = formData;

  return (
    <MainContainer>
      <Container mt="0px">
        <Heading style={{ marginBottom: "1rem" }}>Get Started</Heading>
        <form onSubmit={handleSubmit}>
        <InputField       
          label="Name of your Business"
          placeholder="Enter business name"
          type="text"
          minLength={5}
          maxLength={50}
          value={businessName}
          onBlur={(e) => handleBlur("businessName", e.target.value, validationPatterns.businessName.regex)}
          onChange={(e) => handleFieldChange("businessName", e.target.value)}
          error={errorFields.businessName ? validationPatterns.businessName.message : null}
          required
        />
        <InputField
          label="Business Email ID"
          placeholder="name@company.com"  
          type="email"
          value={emailId}
          onChange={(e) => handleFieldChange("emailId", e.target.value)}
          onBlur={(e) => handleBlur("emailId", e.target.value, validationPatterns.emailId.regex)}
          error={errorFields.emailId ? validationPatterns.emailId.message : null}
          required
        />
        <InputField
          label="Mobile number"
          placeholder="Enter 10 digit mobile number"
          type="tel"
          value={mobileNumber}
          onChange={(e) => handleFieldChange("mobileNumber", e.target.value)}
          onBlur={(e) => handleBlur("mobileNumber", e.target.value, validationPatterns.mobileNumber.regex)}
          error={errorFields.mobileNumber ? validationPatterns.mobileNumber.message : null}
          required
        />
        <InputLabel>
          Create Password <Asterisk>*</Asterisk>
        </InputLabel>
        <InputContainer>
          <Input
            type={passwordVisible ? "text" : "password"}
            placeholder=" Enter password"
            value={password}
            onChange={(e) => handleFieldChange("password", e.target.value)}
            onBlur={(e) => handleBlur("password", e.target.value, validationPatterns.password.regex)}
            required
          />
          <PasswordIcon onClick={togglePasswordVisibility}>
            {passwordVisible ? <InputIcon><BsEye /></InputIcon> : <InputIcon><BsEyeSlash /></InputIcon>}
          </PasswordIcon>
        </InputContainer>
        {
          errorFields.password ? <ErrorText>{validationPatterns.password.message}</ErrorText> : null
        }

        <RememberForgotWrapper>
          <RememberCheckbox type="checkbox" />
          <RememberText>I agree to all the <LineLink to="#">Terms of Service</LineLink> and <LineLink to="#">Privacy Policy</LineLink> </RememberText>
          <ForgotLink></ForgotLink>
        </RememberForgotWrapper>
        <FormButton type='submit'>Sign up</FormButton>
        <SignupText>
          Already have an account with Kahipay? <SignupLink to="/login">Login</SignupLink>
        </SignupText>
        {apiResponse.message && (
            <Alert
              type={apiResponse.success ? "success" : "error"}
              message={apiResponse.message}
            />
          )}
        </form>
      </Container>
    </MainContainer>
  )
}


// Styled components
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid ${theme.colors.skyBluedark};
  border-radius: 5px;
  padding: 8px 4px;
`;

const RememberText = styled.span`
    font-size: 13px;
`;

const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background-color: inherit;
  width: 100%;
  &::placeholder {
    font-size: 15px;
  }
`;

const LineLink = styled(LinkBase)`

`;

