const theme = {
  colors: {
    primary: "#1da1f2",
    secondary: "black",
    skyBluedark: "#0095f3",
    lightBlack: "#424949",
    white: "white",
  },
  bgColors: {
    primary: "#f3fbff",
  },
  shadows: {
    default: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    hover: "#1da1f2 0px 3px 8px",
  },
  fontSizes: {
    heading0: "2.7rem",
    heading1: "2.6rem",
    heading2: "2rem",
    heading3: "1.3rem !important",
    heading4: "2rem",
    heading5: "15px",
    heading6: "1.7rem",

    paragraph1: "1.25rem",
    paragraph2: "1rem",
    paragraph3: "17px",
    paragraph4: "20px",
    paragraph5: "15px",
  },
};

export default theme;
