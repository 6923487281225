import React from "react";
import CommonDiv1 from "./components/CommonDiv1";
import { HeroContainer } from "../../../assets/styles/theme/styles";
import Images from "../../utils/Images";

export default function Pricing() {
  return (
    <>
      {/* <HeroContainer imageUrl={IMAGES.smsbgdiv1} ht="1200px" bgSize="cover"> */}
      <CommonDiv1
        title={`Compare Benefits &
        Pricing to find the plan
        that suits your goals.`}
        description={`Our pricing plans are designed to suit the needs of
        individuals, businesses, and enterprises alike.
        Choose the plan that best fits your requirements and
        unlock the full potential of our product/service.`}
        fgimage={Images.Pricing}
        btns={["Contact Us"]}
      />
      {/* </HeroContainer> */}
    </>
  );
}
