const {
  REACT_APP_API_TOKEN,
  REACT_APP_MAIL_API_TOKEN,
  REACT_APP_SEND_OTP,
  REACT_APP_WEB_POST_MAIL_API,
  REACT_APP_CONTACT_EMAIL1,
  REACT_APP_CONTACT_EMAIL2,
  REACT_APP_PARTNER_EMAIL,
  REACT_APP_CAREER_EMAIL,
  REACT_APP_VERIFIED_EMAIL,
  REACT_APP_VALID_USER,
  REACT_EMAIL_ONBOARD_API_TOKEN,
  REACT_APP_CUSTOMER_ONBOARD,
  REACT_APP_VERIFY_ZIP,
  REACT_APP_SIGNUP_URL,
} = process.env;

export const apiToken = REACT_APP_API_TOKEN
  ? REACT_APP_API_TOKEN
  : `1d3bd5891a06eab3e41c778442243361b45f65a1`;

export const mailapiToken = REACT_APP_MAIL_API_TOKEN
  ? REACT_APP_MAIL_API_TOKEN
  : `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhbnVwYW0iLCJpYXQiOjE2ODExOTI2MDYsImV4cCI6MjU0NTE5MjYwNn0.dwyr7bcdT7q-po66guNSaYPIDxGQSFc6EG_EcUfTvmc`;

export const emailapiToken = REACT_EMAIL_ONBOARD_API_TOKEN
  ? REACT_EMAIL_ONBOARD_API_TOKEN
  : `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJUd2Vic2l0ZSIsImlhdCI6MTY4MjY4MjExOSwiZXhwIjoyMTE0NjgyMTE5fQ.vC_zM-ycZkABlKClrJDPavQGZtURNUFp9VRJe1B1wPo`;
export const sendOtp = REACT_APP_SEND_OTP
  ? REACT_APP_SEND_OTP
  : `https://portal.tubelightcommunications.com/2FA/v1/send-otp/`;

export const verifiedEmail = REACT_APP_VERIFIED_EMAIL
  ? REACT_APP_VERIFIED_EMAIL
  : `http://43.205.226.148:8085/common/api/v1/validate/email`;

export const validUser = REACT_APP_VALID_USER
  ? REACT_APP_VALID_USER
  : `http://43.205.226.148:8085/api/v1/auth/validate/user`;

export const customerOnboard = REACT_APP_CUSTOMER_ONBOARD
  ? REACT_APP_CUSTOMER_ONBOARD
  : `http://43.205.226.148:8085/api/v1/auth/customer`;

export const verifyZip = REACT_APP_VERIFY_ZIP
  ? REACT_APP_VERIFY_ZIP
  : `http://103.209.99.11:5010`;

export const sendMail = REACT_APP_WEB_POST_MAIL_API
  ? REACT_APP_WEB_POST_MAIL_API
  : `https://portal.tubelightcommunications.com/cpaas-global/api/v1/send-email/`;

export const signupURL = REACT_APP_SIGNUP_URL
  ? REACT_APP_SIGNUP_URL
  : `http://43.205.226.148:8069/kahipay/api/authentication`;

// export const sendContact1 = REACT_APP_CONTACT_EMAIL1
//   ? REACT_APP_CONTACT_EMAIL1
//   : `admin@kahipay.com`;

export const sendContact1 = REACT_APP_CONTACT_EMAIL1
  ? REACT_APP_CONTACT_EMAIL1
  : `suraj.yadav@tubelightcommunications.com`;

// export const sendContact2 = REACT_APP_CONTACT_EMAIL2
//   ? REACT_APP_CONTACT_EMAIL2
//   : `tushar@kahipay.com`;
