import React, { useState } from "react";
import styled from "styled-components";
import { MainContainer } from "../../assets/styles/theme/styles";
import theme from "../../assets/styles/theme/theme";
import { Link } from "react-router-dom";
import { TfiEye } from 'react-icons/tfi';
import { MdEmail, MdLock} from 'react-icons/md';
import { BsEyeSlash,BsEye } from 'react-icons/bs';
import usePasswordToggle from "../utils/usePasswordToggle";
import { post } from "../utils/apiManager";
import { ErrorText } from "../utils/InputField";
import Alert from "../utils/Alert";



const Login = () => {
  const [passwordVisible, togglePasswordVisibility] = usePasswordToggle();

  // State variables to store form input data
  const [formData, setFormData] = useState({
    emailId: '',
    password: ''  
  }); 

  const [errorFields, setErrorFields] = useState({
    emailId: false,
    password: false
  });

  const [apiResponse, setApiResponse] = useState({
    success: false,
    message: "",
  });

  const regexPatterns = {
    emailId: {
      regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: "Please enter a valid email Id",
    },
    password: {
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.*()_&])[A-Za-z\d@.*()_&]{8,15}$/,
      message: "Incorrect Password",
    },
  };

  // Event handler for input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
  const { name, value } = e.target;
    const regex = regexPatterns[name].regex; 
    // Test the input value against the regex pattern
    const isValid = regex.test(value);  // valid - true,  not valid - false.

        setErrorFields((prevErrorFields) => ({
          ...prevErrorFields,
          [name]: !isValid 
        }));
  };    


  const handleSubmit =  (e) => {
    e.preventDefault();
      // console.log(`You clicked login button formData: ${JSON.stringify(formData)}`);
      // console.log(`You clicked login button formData: ${JSON.stringify(apiResponse)}`);
      
      // Reset the apiResponse state to initial values
        setApiResponse({
          success: false,
          message: "",
        });

        // console.log(`Inside before hitting api why not updating message `);
      let hasError = false;

      for (const field in formData) {
        if (formData.hasOwnProperty(field)) {
          const value = formData[field];
          const regexPattern = regexPatterns[field].regex;
          const isValid = regexPattern.test(value);
    
          if (!isValid) {
            setErrorFields((prevErrorFields) => ({
              ...prevErrorFields,
              [field]: true,
            }));
            hasError = true; //Set the error flag to true if any field is invalid
          }
        }
      }

      //if not valid email id then alert error message.
      // if(errorFields.password && !errorFields.emailId){
      //   // console.log(`Inside before hitting api password checking`);
      //   setApiResponse({
      //     success: false,
      //     message: "Password Incorrect",
      //   });
      // }

      if(!hasError){
        post('/login', formData)
        .then(data => {
          console.log('POST response:', data);
          // Clear the formData after a successful API hit
          setFormData({
            emailId: "",
            password: ""
          });
      // Update the state with the API response
      setApiResponse({
        success: true, //Set to true for success, false for error
        // message: data.message, // set static or dynamic response message
        message: "Login Successful  ", // set static or dynamic response message
      });

        })
        .catch(error => {
          console.error('POST error:', error);
          // Update the state with the API response
          setApiResponse({
            success: false,
            message: error.response.data.message,
          });
        });
      }
  }


  return (
    <MainContainer>
    <Container>
      <Heading>Login</Heading>
      <SubHeading>Login to your merchant account</SubHeading>
      <form onSubmit={handleSubmit}>
      <InputContainer>
        <InputIcon><MdEmail/></InputIcon>
        <Input 
          type="email" 
          name="emailId" 
          placeholder="Enter Email ID" 
          value={formData.emailId} 
          onChange={handleInputChange}
          onBlur={handleBlur} 
          required
        />
      </InputContainer>
      {
          errorFields.emailId? //if not valid then show this error message.
          <ErrorText style={{marginTop: "-10px"}}>{regexPatterns.emailId.message}</ErrorText> 
          : null
        }
      <InputContainer>
        <InputIcon><MdLock/></InputIcon>
        <Input
          type={passwordVisible ? "text" : "password"}
          name="password"
          placeholder="Password"
          minLength={8}
          maxLength={15}
          value={formData.password}
          onBlur={handleBlur} 
          onChange={handleInputChange}
          required
        />
        <PasswordIcon onClick={togglePasswordVisibility}>
          {passwordVisible ? <InputIcon><BsEye/></InputIcon> : <InputIcon><BsEyeSlash/></InputIcon>}
        </PasswordIcon>
      </InputContainer>
      {
          errorFields.password? //if not valid then show this error message.
          <ErrorText style={{marginTop: "-10px"}}>{regexPatterns.password.message}</ErrorText> 
          : null
        }
      <RememberForgotWrapper>
        <RememberCheckbox type="checkbox" />
        <RememberText>Remember me</RememberText>
        <ForgotLink to="/kyc-detail">Forgot Password?</ForgotLink>
      </RememberForgotWrapper>
      <FormButton type="submit">Login</FormButton>
      </form>
      <SignupText>
        Don't have an account with Kahipay? <SignupLink to="/signup">Sign Up</SignupLink>
      </SignupText>
      {apiResponse.message && (
            <Alert
              type={apiResponse.success ? "success" : "error"}
              message={apiResponse.message}
            />
          )}
    </Container>
    </MainContainer>
  );
};

export default Login;

// Styled components
const Container = styled.div`
  max-width: 400px;
  margin: 0px auto ;
  // height: 80vh;
  padding-top: ${(props)=>props.mt? props.mt : "3rem"};
`;

const Heading = styled.h2`
  font-weight: bold;
  text-align: center;
`;

const SubHeading = styled.p`
  margin-top: 10px;
  text-align: center;

`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid ${ theme.colors.skyBluedark};
  border-radius: 5px;
  margin: 10px 0;
  padding: 8px 4px;
`;

const InputIcon = styled.span`
color: ${ theme.colors.skyBluedark};
margin: 0 10px;
font-size: 22px;

`;

const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background-color: inherit;
  width: 100%;

`;

const PasswordIcon = styled.span`
  cursor: pointer;
`;

const RememberForgotWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const RememberCheckbox = styled.input`
  margin-right: 10px;
`;

const RememberText = styled.span``;

const FormButton = styled.button`
  background-color: ${theme.colors.skyBluedark};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  font-weight: bold;
`;

const SignupText = styled.p`
  margin-top: 20px;
  text-align: center;
`;

const LinkBase = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.skyBluedark};
`; 

const SignupLink = styled(LinkBase)`
  font-weight: bold;
`;

const ForgotLink = styled(LinkBase)`
  margin-left: auto;    

`;
export {
    Heading,
    Container,
    FormButton,
    SignupText,
    SignupLink,
    InputIcon,
    Input,
    PasswordIcon,
    RememberForgotWrapper,
    RememberCheckbox,
    RememberText,
    ForgotLink,
    LinkBase

};