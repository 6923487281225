import React from "react";
import Heading from "./Heading";
import styled from "styled-components";
// import theme from "../assets/styles/theme/theme";
// import LinkComponent from "./Link";
import { MainContainer } from "../../assets/styles/theme/styles";
import Paragraph from "./Paragraph";
import theme from "../../assets/styles/theme/theme";

const HeroItem = styled.div`
  /* add styles for hero item */
  margin-bottom: 3rem;
`;

const WeHelpContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
`;

const WeHelpItem = styled.div`
  color: ${theme.colors.secondary};
  text-align: center;
  width: 373px;
  margin: 10px;
  position: relative;

  &:not(:nth-child(2)):not(:nth-child(5))::before {
    content: "";
    position: absolute;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: ${theme.colors.primary};
  }

  &:not(:nth-child(2)):not(:nth-child(5)):nth-child(odd)::before {
    left: -18px; /* Line on the left for odd cards */
  }

  &:not(:nth-child(2)):not(:nth-child(5)):nth-child(even)::before {
    right: -18px; /* Line on the right for even cards */
  }

  &:not(:nth-child(2)):not(:nth-child(5)):nth-child(1)::before {
    left: auto;
    right: -18px; /* Line on the right for the first card */
  }
  @media (max-width: 1200px) {
    &:before {
      display: none; /* Hide lines on screens less than 1200px */
    }
  }
  @media (min-width: 1020px) {
    text-align: left;
  }
`;

export default function GridTextContainer({
  heading,
  helpContent,
  link,
  para,
}) {
  return (
    <>
      {/* <HeroContainer> */}
      <MainContainer>
        <HeroItem>
          <Heading level={1} style={{ textAlign: "center" }}>
            {heading}
          </Heading>
          {para && <Paragraph level={1}>{para}</Paragraph>}
        </HeroItem>
        <WeHelpContainer>
          {helpContent.map((content, index) => (
            <WeHelpItem key={index}>
              <Heading level={3} style={{ textAlign: "center" }}>
                {content.title}
              </Heading>
              <Paragraph level={2} style={{ marginBottom: "0px" }}>
                {content.description}
              </Paragraph>
              {/* {link && <LinkComponent text="Learn more" />} */}
            </WeHelpItem>
          ))}
        </WeHelpContainer>
      </MainContainer>
      {/* </HeroContainer> */}
    </>
  );
}

// GridTextContainer , IndustryItDiv2: ye dono ki styling same hai.
