import React from "react";
import styled from "styled-components";
import { MainContainer } from "../../../../assets/styles/theme/styles";
import RedirectToPages from "../../../utils/RedirectToPages";
import Button from "../../../utils/Button";
import theme from "../../../../assets/styles/theme/theme";
import { fadeIn } from "../../../../assets/styles/animation";

const CommonDiv1 = ({
  title,
  description,
  bgimage,
  fgvideo,
  fgimage,
  btns,
  video,
}) => {
  return (
    <>
      <MainContainer>
        <FlexContainer>
          <TextContainer>
            <h1>{title}</h1>
            <p dangerouslySetInnerHTML={{ __html: description }}>
              {/* {description}s */}
            </p>
            <BtnsWrapper>
              {btns &&
                btns.map((item, ind) => {
                  return (
                    <RedirectToPages href="contact-us">
                      <Button
                        text={item}
                        key={ind}
                        bgColor={theme.colors.primary}
                      />
                    </RedirectToPages>
                  );
                })}
            </BtnsWrapper>
          </TextContainer>
          <div></div>
          <ImageColumn>
            {!video && <img src={fgimage} alt="" />}
            {/* {video &&
              (fgimage && fgvideo ? (
                <BrowserCompatibility image={fgimage} video={fgvideo} />
              ) : null)} */}
          </ImageColumn>
        </FlexContainer>
      </MainContainer>
    </>
  );
};

export default CommonDiv1;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  // gap: 5rem;
  gap: 1rem;

  @media (min-width: 1020px) {
    flex-direction: ${(props) => props.flxdrn || "row"};
  }

  > div {
    flex-basis: 1;
  }
`;

const TextContainer = styled.div`
  // text-align: left;
  text-align: center;
  // padding: 15px;

  @media (min-width: 720px) {
  }
  @media (min-width: 1020px) {
    flex-direction: row-reverse;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 48%;
  }

  h1 {
    // font-size: 1.5rem;
    font-size: ${theme.fontSizes.heading2};
    margin-bottom: 12px;
    color: ${theme.colors.secondary};
    animation: ${fadeIn} 1s ease-in-out;
    font-weight: bolder;
    max-width: 590px;

    @media (min-width: 1020px) {
      font-size: ${theme.fontSizes.heading0};
      margin-bottom: 1rem;
    }
  }

  p {
    max-width: 392px;
    font-size: ${theme.fontSizes.paragraph2};
    // font-size: 17px;
    animation: ${fadeIn} 1s ease-in-out;
  }
`;

const ImageColumn = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  display: grid;
  place-items: center;
  background-position-x: -113px;

  @media (min-width: 720px) {
    background-position-x: unset;
    background-position: center;
  }

  @media (min-width: 1020px) {
    background-image: none !important;
    width: 35%;
  }

  img {
    max-width: 350px;
    height: auto;

    @media (min-width: 1020px) {
      padding-right: 0;
      max-width: 580px;
    }
  }

  video {
    width: 100%;
    background-color: transparent;
  }

    @media (min-width: 720px) {
      width: 580px;
    }
  }
`;

const BtnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  // margin-top: 3rem;
  margin-top: 1rem;

  @media (min-width: 1020px) {
    justify-content: flex-start;
  }
`;
