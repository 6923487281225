import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RxHamburgerMenu, RxCross1, RxTriangleDown } from 'react-icons/rx';
import Dropdown from "./Dropdown/Dropdown";
import Button from "../utils/Button";
import styled from 'styled-components';
import Images from "../utils/Images";
import BrandLogo from "../utils/BrandLogo";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => {
    setClick(!click);
    if (click==true && typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'unset';

    } else {
      document.body.style.overflow = 'hidden';
    }

  }
  const closeMobileMenu = () => {
    setClick(false);
    document.body.style.overflow = 'unset';
  }

  const onMouseEnter = () => {
    if (window.innerWidth < 800) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 800) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return (
    <>
    <NavbarWrapper>
      <NavbarContainer>
        <BrandLogo />
        <MenuIcon onClick={handleClick}>
          {click ? <RxCross1 /> : <RxHamburgerMenu />}
        </MenuIcon>
        <NavMenu click={click}>
          <NavItem>
            <NavLinks to="/" onClick={closeMobileMenu}>
              Home
            </NavLinks>
          </NavItem>
          <NavItem
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <NavLinks to="/QR-based-payment" onClick={closeMobileMenu}>
              Products
              <RxTriangleDown style={{ position: "relative", bottom: "2px" }} />
            </NavLinks>
            {dropdown && <Dropdown />}
          </NavItem>
          <NavItem>
            <NavLinks to="/pricing" onClick={closeMobileMenu}>
              Pricing
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="/about-us" onClick={closeMobileMenu}>
              About Us
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="/contact-us" onClick={closeMobileMenu}>
              Contact us
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="/login">
              {/* <NavLinksMobile> */}
                <Button text="Login / Signup" onClick={closeMobileMenu}/>
              {/* </NavLinksMobile> */}
            </NavLinks>
          </NavItem>
        </NavMenu>
      </NavbarContainer>
      </NavbarWrapper>
    </>
  );
}

export default Navbar;

const NavbarContainer = styled.nav`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  max-width: 1217.6px;
  margin: 0px auto;


  @media screen and (max-width: 1159px) {
    height: 80px;
    justify-content: space-between;
    padding: 2rem;
  }
  }
`;

const NavbarWrapper = styled.div`
background-color: white;
width: 100%;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

`;



const MenuIcon = styled.div`
  display: none;

  @media screen and (max-width: 1159px) {
    display: block;
    top: 0;
    right: 0;
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

const NavMenu = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
  align-items: center;

  @media screen and (max-width: 1159px) {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-top: 2rem;
    width: 100%;
    background-color: black;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: ${props => (props.click ? "0" : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
`;

const NavItem = styled.li`
  display: flex;
  align-items: center;
  height: 80px;
`;

const NavLinks = styled(Link)`
  color: black;
  text-decoration: none;
  padding: 0.5rem 1rem;

  &:hover {
    color: #0095f3;
    transition: all 0.2s ease-out;
  }

  @media screen and (max-width: 1159px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: white;

    &:hover {
      color: #0095f3;
      border-radius: 0;
    }
  }
`;

const NavLinksMobile = styled(Link)`

  @media screen and (max-width: 1159px) {
    button {
      background-color: #0095f3;
    }

    &:hover {
     
    }
  }
`;