const Images = {
  company_logo: require("../../assets/images/Kahipay_Logo.png"),
  contact_us: require("../../assets/images/Contactus.png"),
  About_us: require("../../assets/images/Aboutus.png"),
  Pricing: require("../../assets/images/Pricing.png"),
  home: require("../../assets/images/Home.png"),
  qrpay: require("../../assets/images/QRpayment.png"),
  paySpeaker: require("../../assets/images/paySpeaker.png"),
  home2: require("../../assets/images/Home 2.png"),
  home3: require("../../assets/images/Home 3.png"),
  icon1: require("../../assets/images/Icon 1.png"),
  icon2: require("../../assets/images/Icon 2.png"),
  icon3: require("../../assets/images/Icon 3.png"),
};

export default Images;
