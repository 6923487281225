import styled, { keyframes } from "styled-components";
import theme from "./theme";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.gap || "2rem"};
  justify-content: ${(props) => props.jfycont || "space-between"};

  @media (min-width: 1020px) {
    flex-direction: ${(props) => props.flxdrn || "row"};
  }

  > div {
    flex: ${(props) => props.flex || ""};
  }
`;

const HeroContainer = styled.section`
  background-color: ${theme.bgColors.primary};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.imageUrl});

  @media (min-width: 1020px) {
    background-size: ${(props) => (props.bgSize ? props.bgSize : "contain")};
    background-position: center;
    padding: 0px 3rem;
    ${(props) => (props.ht ? props.ht : "auto")};
  }
`;

const MainContainer = styled.div`
  margin: 0 auto;
  max-width: 1340px;
  padding: calc(16px + 1.5625vw);
  text-align: center;

  @media (min-width: 1020px) {
    text-align: left;
  }
`;

const HalfContainer = styled.div`
  max-width: 690px;
`;

export { FlexContainer, MainContainer, HeroContainer, HalfContainer };
